.logo {
    width: 200px; /* Adjust based on your actual logo size */
  }
  
  .success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .success-content {
    background-color: white;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 50%;
    border: 1px solid rgb(198, 200, 198);
    margin-top: 50px;
  }
  
  .success-icon svg {
    color: #4caf50; /* Green */
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  h1 {
    color: #333;
    margin: 20px 0;
  }
  
  p {
    color: #666;
    font-size: 20px;
  }
  
  .svg-image{
    width: 60%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .uniqueId{
    font-weight: bold;
  }