.form-container {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50%;

  margin-top: 5px;
}

.form-input {
  width: 100%;
  padding: 20px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 16px;
}

.submit-button {
  width: 100%;
  background-color: #3f51b5;
  color: white;
  padding: 20px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
}

.submit-button:hover {
  background-color: #303f9f;
}

.disclaimer {
  margin-top: 20px;
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 8px;
  font-size: 14px;
}

.disclaimer strong {
  display: block;
  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.disclaimer > p {
  margin-top: 1rem;
  font-size: 14px;
}

.form-title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.date-input {
  position: relative;
  width: 48%;
}

.date-container {
  display: flex;
  justify-content: space-between;
}

.error-field {
  color: rgb(214, 23, 23);
  font-size: medium;
}

/* for browsers */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
