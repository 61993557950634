.pageError-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #f8f9fa;
  color: #343a40;
  text-align: center;
}

.pageError-content {
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pageError-heading {
  font-size: 5em;
  margin: 0;
  color: #dc3545;
}

.pageError-subHeading {
  font-size: 2em;
  margin: 10px 0;
}
.pageError-message {
  font-size: 1.2em;
  margin-bottom: 20px;
}
