.home-screen {
  height: auto;
}

.selection-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.selection-heading {
  color: grey;
}
