.header-section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-image {
  transform: scale(1.2);
  margin-left: auto;
  margin-right: auto;
}

.header {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.ruler {
    width: 100%;
    height: 1px;
    background-color: rgb(210, 208, 208);
}