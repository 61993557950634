.radio-button-group {
    display: flex;
    gap: 2rem;
    font-weight: 200;
    font-size: large;
}

.radio-button {
    display: flex;
    gap: 1rem;
    font-weight: 400;
}

.button-input {
    transform: scale(1.5);
}
.button-input:hover{
    cursor: pointer;
}